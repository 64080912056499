import React from "react";
import { FaFacebookSquare, FaTwitter, FaInstagram } from "react-icons/fa";

import Logo from "@assets/logo-dark.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import scrollToSection from "@utils/scrollToSection";
import logoHandler from "../../utils/logoHandler";

const Footer = () => {
  const navigate = useNavigate();
  const path = useLocation().pathname;

  return (
    <div className="bg-gray-600 text-center space-y-12 py-20">
      <img src={Logo} className="text-center mx-auto h-16 sm:h-24" alt="" />
      <div className="space-x-9 text-sm text-white flex items-center flex-wrap justify-center gap-2">
        <p
          onClick={() => logoHandler(path, navigate)}
          className="cursor-pointer hover:text-main tracking-widest"
        >
          NimTalk LLC © 2023
        </p>
        <NavLink
          to="/"
          className="cursor-pointer  hover:text-main tracking-widest"
          onClick={() => scrollToSection(path, "features", navigate)}
        >
          Features
        </NavLink>
        <NavLink
          to="/"
          className="cursor-pointer  hover:text-main tracking-widest"
          onClick={() => scrollToSection(path, "screenshot", navigate)}
        >
          Screenshot
        </NavLink>
        <NavLink
          to={"/terms-and-conditions"}
          className="cursor-pointer  hover:text-main tracking-widest"
        >
          Terms and Conditions
        </NavLink>
        <NavLink
          to={"/privacy-policy"}
          className="cursor-pointer  hover:text-main tracking-widest"
        >
          Privacy Policy
        </NavLink>
        <NavLink
          to={"/refund-policy"}
          className="cursor-pointer  hover:text-main tracking-widest"
        >
          Refund Policy
        </NavLink>
        <NavLink
          to="/"
          className="cursor-pointer  hover:text-main tracking-widest"
          onClick={() => scrollToSection(path, "contact-us", navigate)}
        >
          Contact
        </NavLink>
      </div>
      <div className="text-main text-2xl sm:text-5xl flex items-center justify-center gap-5">
        <a
          href="https://www.facebook.com/NimTalk"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookSquare />
        </a>
        <a
          href="https://twitter.com/NimTalkLLC"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter />
        </a>
        <a
          href="https://www.instagram.com/nimtalk"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram />
        </a>
      </div>
    </div>
  );
};

export default Footer;
