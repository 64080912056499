import { BsApple, BsGooglePlay } from "react-icons/bs";

import BannerPng from "@assets/pngs/banner.png";

const Banner = () => {
  return (
    <section id="banner" className="relative mb-28">
      <div className="h-[60vh] sm:h-[70vh] lg:h-[93vh] bg-main absolute w-full top-0 z-0 transform -skew-y-6 lg:-skew-y-3" />
      <div className="bg-main flex justify-center items-center relative min-h-[50vh] sm:min-h-[60vh] lg:min-h-[80vh] pt-32">
        <div className="hidden lg:block">
          <img src={BannerPng} className="w-[40rem]" alt="" />
        </div>
        <div className="text-white px-10 lg:px-20 max-w-2xl space-y-6 text-center lg:text-left">
          <p className="text-3xl sm:text-5xl lg:text-7xl">
            Speak freely with your friends
          </p>
          <p className="text-xl sm:text-2xl lg:text-4xl font-thin">
            Secure messaging and calling for free
          </p>
          <div className="flex items-center justify-center lg:justify-start gap-4 pt-4 flex-wrap">
            <button className="bg-white text-main hover:text-white hover:bg-main border border-main hover:border-white py-2 sm:py-3 px-5 rounded-lg flex items-center">
              <BsGooglePlay size={30} />
              <span className="ms-2">GOOGLE PLAY</span>
            </button>
            <button className="bg-white text-main hover:text-white hover:bg-main border border-main hover:border-white py-2 sm:py-3 px-5 rounded-lg flex items-center">
              <BsApple size={30} />
              <span className="ms-2">APP STORE</span>
            </button>
          </div>
        </div>
      </div>
      {/* <div className="h-[91.8vh] xl:h-[93vh] bg-main absolute w-[100.8vw] top-0 z-0 -rotate-3 -translate-x-9" /> */}
    </section>
  );
};

export default Banner;
