import Banner from "@components/banner";
import Features from "@components/features";
import BorderLine from "@components/common/borderLine";
import ContactUs from "@components/contactUs";
import Layout from "@layout";

const Home = () => {
  return (
    <Layout>
      <Banner />
      <Features />
      <BorderLine />
      <ContactUs />
    </Layout>
  );
};

export default Home;
