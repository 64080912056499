import Layout from "@layout";
import { useEffect, useState } from "react";
import StoreCard from "../../components/storeCard";
import { FadeLoader } from "react-spinners";

const stripe = require("stripe")(process.env.REACT_APP_STRIPE_SECRET_KEY);

const Store = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      const products = await stripe.products.list();

      // setProducts(products.data);
      if (products.data.length > 0) {
        const updatedProducts = await Promise.all(
          products.data.map(async (product) => {
            if (product.default_price) {
              const price = await stripe.prices.retrieve(product.default_price);
              if (price) product.price = price;
            }
            return product;
          })
        );
        setProducts(updatedProducts);
      }
    };
    getProducts();
  }, []);

  return (
    <Layout>
      <div className="flex flex-wrap min-h-screen justify-center items-center">
        <div className="w-full mx-auto bg-white px-5 py-10 text-gray-600 my-20">
          <div className="max-w-5xl mx-auto md:flex flex-col">
            <div className="md:flex justify-center md:flex-col mb-12">
              <div className="text-left w-full flex-grow md:pr-5">
                <h1 className="text-4xl text-center font-bold mb-5">Pricing</h1>
                <h3 className="text-md font-medium text-center mb-5">
                  Purchase NimTalk Points to Boost your Chatroom rankings and
                  make your accounts more beautiful with badges & colors using
                  points.*
                </h3>
              </div>
              <div className="w-full text-center mb-2">
                <p className="text-3xl tracking-widest font-bold text-main">
                  *NimTalk Pakages
                </p>
              </div>
            </div>
            <div className="">
              <div className="mx-auto md:flex justify-center flex-wrap gap-3">
                {products.length > 0 ? (
                  products
                    .slice(0, products.length - 2)
                    .map((product, i) => (
                      <StoreCard key={i} stripe={stripe} product={product} />
                    ))
                ) : (
                  <FadeLoader color="#f18221" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Store;
