import { useEffect } from "react";
import { animateScroll } from "react-scroll";

import Navbar from "@components/navbar";
import Footer from "@components/footer";

const Layout = ({ children }) => {
  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  return (
    <div className="bg-white flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-1">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
