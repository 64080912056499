import { useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Sheet, Content, Footer, detents, Portal } from "react-sheet-slide";
import "react-sheet-slide/style.css";

import scrollToSection from "@utils/scrollToSection";

const NavbarModal = ({ modal, setModal }) => {
  const ref = useRef();
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const toggle = () => setModal(!modal);

  return (
    <div className="rss-backdrop bg-red-400 shadow">
      <Portal className="relative z-50">
        <Sheet
          ref={ref}
          open={modal}
          onDismiss={toggle}
          selectedDetent={detents.large}
          detents={(props) => [detents.large(props), detents.medium(props)]}
          useDarkMode={false}
          scrollingExpands={true}
        >
          <Content className="rss-content">
            <div
              className="uppercase font-light text-black grid grid-cols-1"
              style={{ padding: "54px 16px 24px" }}
            >
              <NavLink
                to="/"
                className="cursor-pointer my-2 py-2 hover:bg-main hover:text-white px-2 hover:rounded-md"
                onClick={() =>
                  scrollToSection(path, "features", navigate, toggle)
                }
              >
                Features
              </NavLink>
              <hr />
              <NavLink
                to="/"
                className="cursor-pointer my-2 py-2 hover:bg-main hover:text-white px-2 hover:rounded-md"
                onClick={() =>
                  scrollToSection(path, "screenshot", navigate, toggle)
                }
              >
                screenshot
              </NavLink>
              <hr />
              <NavLink
                to={"/terms-and-conditions"}
                className="cursor-pointer my-2 py-2 hover:bg-main hover:text-white px-2 hover:rounded-md"
              >
                Terms & conditions
              </NavLink>
              <hr />
              <NavLink
                to={"/privacy-policy"}
                className="cursor-pointer my-2 py-2 hover:bg-main hover:text-white px-2 hover:rounded-md"
              >
                Privacy policy
              </NavLink>
              <hr />
              <NavLink
                to="/"
                className="cursor-pointer my-2 py-2 hover:bg-main hover:text-white px-2 hover:rounded-md"
                onClick={() =>
                  scrollToSection(path, "contact-us", navigate, toggle)
                }
              >
                contact
              </NavLink>
            </div>
          </Content>
          <Footer className="rss-footer">
            <button
              type="button"
              className="hover:bg-white hover:text-main text-white bg-main border hover:border-main border-white py-2 sm:py-3 px-5 rounded-lg w-full"
              onClick={() => setModal(false)}
            >
              Close
            </button>
          </Footer>
        </Sheet>
      </Portal>
    </div>
  );
};

export default NavbarModal;
