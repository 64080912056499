import { animateScroll as scroll } from "react-scroll";

const scrollToSection = (path, section, navigate, func = () => {}) => {
  const scrollToOptions = {
    spy: true,
    smooth: true,
    duration: 1000,
  };

  if (path !== "/") {
    navigate("/");
    if (section === "features")
      setTimeout(() => {
        scroll.scrollTo(910, scrollToOptions);
      }, 100);
    if (section === "contact-us")
      setTimeout(() => {
        scroll.scrollToBottom();
      }, 100);
  } else {
    if (section === "features") scroll.scrollTo(910, scrollToOptions);
    if (section === "contact-us") scroll.scrollToBottom();
  }
  func();
};

export default scrollToSection;
