import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import Logo from "@assets/logo.png";
import useScrollPosition from "@hooks/useScrollPosition";
import NavbarModal from "@components/navbar/navbarModal";
import scrollToSection from "@utils/scrollToSection";
import logoHandler from "../../utils/logoHandler";

const Navbar = () => {
  const scrollPosition = useScrollPosition();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const path = useLocation().pathname;

  return (
    <div
      className={`${
        scrollPosition > 100
          ? "bg-orange-600 border-white"
          : "bg-main border-main"
      } flex items-center justify-between border-b sticky top-0 z-10`}
    >
      <img
        src={Logo}
        className="h-8 sm:h-12 cursor-pointer"
        onClick={() => logoHandler(path, navigate)}
        alt=""
      />
      <NavbarModal modal={modal} setModal={setModal} />

      <div className="uppercase font-extralight text-sm text-white flex items-center">
        <div className="hidden lg:block space-x-9 me-3">
          <NavLink
            to="/"
            className="cursor-pointer"
            onClick={() => scrollToSection(path, "features", navigate)}
          >
            Features
          </NavLink>
          <NavLink
            to="/"
            className="cursor-pointer"
            onClick={() => scrollToSection(path, "screenshot", navigate)}
          >
            screenshot
          </NavLink>
          <NavLink to={"/terms-and-conditions"} className="cursor-pointer">
            Terms & conditions
          </NavLink>
          <NavLink to={"/privacy-policy"} className="cursor-pointer">
            Privacy policy
          </NavLink>
          <NavLink
            to="/"
            className="cursor-pointer"
            onClick={() => scrollToSection(path, "contact-us", navigate)}
          >
            contact
          </NavLink>
        </div>
        <button
          className="lg:hidden hover:bg-white hover:text-main text-white bg-orange-400 border hover:border-main border-white py-1 px-5 rounded m-2 shadow"
          onClick={() => setModal(true)}
        >
          MENU
        </button>
        {/* <NavLink
          to="/store"
          className="cursor-pointer hover:bg-orange-500 hover:text-white text-gray-500 bg-gray-100 border hover:border-white border-main py-1 px-5 rounded m-2 me-3 md:me-9 shadow"
        >
          store
        </NavLink> */}
      </div>
    </div>
  );
};

export default Navbar;
