import { Element } from "react-scroll";

const ContactUs = () => {
  return (
    <Element name="contact-us" className="max-w-7xl mx-auto py-16 md:py-24 space-y-10 px-10">
      <p className="text-3xl sm:text-5xl lg:text-7xl text-center">Contact Us</p>
      <p className="sm:text-2xl lg:text-4xl font-extralight mt-3 text-center">
        ASK MORE INFORMATIONS
      </p>
      <p className="text-center">
        If you have any questions, concerns, or requests, please contact us at{" "}
        <a
          href="mailto:support@nimtalk.com"
          className="text-main hover:text-orange-600 hover:underline hover:underline-offset-2"
        >
          support@nimtalk.com
        </a>
      </p>
    </Element>
  );
};

export default ContactUs;
