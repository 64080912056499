import Layout from "@layout";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-16 sm:py-24 space-y-10 px-10">
        <p className="text-3xl md:text-4xl lg:text-5xl text-center uppercase tracking-widest">
          Privacy Policy
        </p>
        <div>
          <span className="bg-main font-bold text-white text-sm sm:text-base md:text-2xl px-1 rounded mr-2">
            NimTalk - Chatrooms & Fun's
          </span>
          <span className="md:text-xl font-light mt-3 text-justify">
            Privacy Policy describes how NimTalk LLC we collects, uses, and
            protects the personal information you provide while using our chat
            application NimTalk - Chatrooms & Fun. By using the App, you consent
            to the terms of this Privacy Policy.
          </span>
        </div>
        <div className="space-y-4 my-4 font-light text-sm sm:text-base md:text-lg">
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">1.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Information We Collect:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    Personal Information: When you register for an account or
                    use the App, we may collect personal information such as
                    your name, email address, username, and profile picture.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    Usage Information: We may collect information about your
                    interactions with the App, including log files, IP
                    addresses, device information, and usage patterns.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">c.</span>
                  <p>
                    User Content: The App allows you to create, upload, and
                    share content such as messages, images, and files. This
                    content may contain personal information.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">2.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Use of Information:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    We may analyze the usage patterns and behavior of our users
                    to enhance the App's performance and optimize user
                    experience.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    We may analyze the usage patterns and behavior of our users
                    to enhance the App's performance and optimize user
                    experience.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">c.</span>
                  <p>
                    We may use aggregated and anonymized data for statistical
                    and research purposes.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">3.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Data Security:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    We employ industry-standard security measures to protect
                    your personal information from unauthorized access,
                    disclosure, or alteration.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    However, no method of transmission over the internet or
                    electronic storage is entirely secure, and we cannot
                    guarantee absolute security.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">4.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Information Sharing:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    We do not sell, trade, or rent your personal information to
                    third parties without your explicit consent.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    We may share your information with trusted third-party
                    service providers who assist us in operating the App, as
                    long as they agree to keep the information confidential.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">c.</span>
                  <p>
                    We may disclose your information if required by law or in
                    response to valid requests by public authorities.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">5.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Cookies and Tracking Technologies:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    The App may use cookies and similar tracking technologies to
                    enhance your experience and collect information about how
                    you use the App.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    You may choose to disable cookies through your browser
                    settings, but this may affect certain functionalities of the
                    App.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">6.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Third-Party Links:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    The App may contain links to third-party websites or
                    services. We are not responsible for the privacy practices
                    or content of such websites or services. We encourage you to
                    review the privacy policies of those third parties.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">7.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Children's Privacy:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    The App is not intended for children under the age of 13. We
                    do not knowingly collect personal information from children
                    under 13. If you believe we have inadvertently collected
                    such information, please contact us, and we will take
                    appropriate steps to delete it.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">8.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Changes to the Privacy Policy:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    We reserve the right to modify or update this Privacy Policy
                    at any time. We will notify you of any material changes
                    through the App or by other means. Your continued use of the
                    App after the changes signifies your acceptance of the
                    updated Privacy Policy.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">9.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Contact Us:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    If you have any questions, concerns, or requests regarding
                    this Privacy Policy or our data practices, please contact us
                    at{" "}
                    <a
                      href="mailto:support@nimtalk.com"
                      className="text-main hover:text-orange-600 hover:underline hover:underline-offset-2"
                    >
                      support@nimtalk.com
                    </a>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
