import { Route, Routes } from "react-router-dom";

import {
  Home,
  PrivacyPolicy,
  TermsAndConditions,
  RefundPolicy,
} from "@pages";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      {/* <Route path="/store" element={<Store />} /> */}
    </Routes>
  );
};

export default AppRoutes;
