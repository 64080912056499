import { BiCheckCircle } from "react-icons/bi";
import { Element } from "react-scroll";

import FeaturePng from "@assets/pngs/feature.png";

const Features = () => {
  return (
    <Element
      name="features"
      id="features"
      className="flex flex-col lg:flex-row justify-evenly pb-10 lg:pb-14 pt-24 lg:pt-32 max-w-7xl mx-auto px-10"
    >
      <div className="text-center lg:text-left">
        <p className="text-3xl sm:text-5xl lg:text-7xl">Great features</p>
        <p className="sm:text-2xl lg:text-4xl font-extralight mt-3">SIMPLE. SECURE. FREE.</p>
        <div className="my-12 space-y-5 hidden lg:block">
          <div className="flex items-center">
            <BiCheckCircle size={35} className="text-main" />
            <span className="ms-2 text-xl font-extralight">
              Create your own Chatroom and have ChitChat with people.
            </span>
          </div>
          <div className="flex items-center">
            <BiCheckCircle size={35} className="text-main" />
            <span className="ms-2 text-xl font-extralight">
              One to One chat is supported.
            </span>
          </div>
          <div className="flex items-center">
            <BiCheckCircle size={35} className="text-main" />
            <span className="ms-2 text-xl font-extralight">
              Customize your chat & username with badges & colors.
            </span>
          </div>
        </div>
        <p className="max-w-xl mx-auto sm:text-lg font-extralight my-10 lg:mt-0 mb-10">
          NimTalk is one of the best chat application with lots of features like
          chatrooms and one to one chat and many more which make people to have
          more fun in their Chatrooms. Download NimTalk now and find new friends
          from around the globe to have more entertainment in your life.
        </p>
        <button className="hover:bg-white hover:text-main text-white bg-main border hover:border-main border-white py-2 sm:py-3 px-5 rounded-lg">
          MORE SCREENSHOTS
        </button>
      </div>
      <div className="flex justify-center mt-20 lg:mt-0">
        <img src={FeaturePng} alt="" className="w-96" />
      </div>
    </Element>
  );
};

export default Features;
