import Layout from "@layout";

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-16 sm:py-24 space-y-10 px-10">
        <p className="text-3xl md:text-4xl lg:text-5xl text-center uppercase tracking-widest">
          Terms and Conditions
        </p>
        <div>
          <span className="bg-main font-bold text-white text-sm sm:text-base md:text-2xl px-1 rounded mr-2">
            Welcome to NimTalk - Chatrooms & Fun
          </span>
          <span className="md:text-xl font-light mt-3 text-justify">
            a great chat application! These Terms and Conditions govern your use
            of the chat application NimTalk - Chatrooms & Fun provided by
            NimTalk LLC. By using the App, you agree to abide by these Terms and
            Conditions. If you do not agree to these terms, please refrain from
            using the App.
          </span>
        </div>
        <div className="space-y-4 my-4 font-light text-sm sm:text-base md:text-lg">
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">1.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                User Conduct:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>You must be at least 13 years old to use the App.</p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    You are responsible for maintaining the confidentiality of
                    your login credentials and are solely responsible for any
                    activity that occurs under your account.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">c.</span>
                  <p>
                    You agree not to use the App for any illegal or unauthorized
                    purpose and must comply with all applicable laws and
                    regulations.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">2.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Content Ownership:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    You retain ownership of the content you submit or transmit
                    through the App.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    By using the App, you grant us a non-exclusive, worldwide,
                    royalty-free license to use, reproduce, modify, adapt,
                    publish, translate, and distribute your content for the
                    purpose of providing and improving the App's functionality.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">3.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Prohibited Activities:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li>
                  <div className="flex">
                    <span className="font-semibold mr-2">a.</span>
                    <p>
                      You agree not to engage in any of the following activities
                      while using the App:
                    </p>
                  </div>
                  <ol className="ml-3 sm:ml-7">
                    <li className="flex">
                      <span className="font-semibold mr-2">i.</span>
                      <p>
                        Uploading, transmitting, or sharing any content that is unlawful, harmful, 
						threatening, abusive, harassing, defamatory, vulgar, obscene, Sexting or otherwise objectionable.
						You will be the answerable to Allah Almighty for your acts not us.
                      </p>
                    </li>
                    <li className="flex">
                      <span className="font-semibold mr-2">ii.</span>
                      <p>
                        Impersonating any person or entity or falsely
                        representing your affiliation with any person or entity.
                      </p>
                    </li>
                    <li className="flex">
                      <span className="font-semibold mr-2">ii.</span>
                      <p>
                        Uploading, transmitting, or sharing any content that
                        infringes upon the intellectual property rights or
                        privacy rights of others.
                      </p>
                    </li>
                    <li className="flex">
                      <span className="font-semibold mr-2">iv.</span>
                      <p>
                        Engaging in any conduct that may disrupt the normal
                        operation of the App or compromise its security.
                      </p>
                    </li>
                  </ol>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    We reserve the right to terminate your accounts and chatrooms on NimTalk -
                    Chatrooms & Fun platform if you engage in prohibited
                    activities.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">4.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Privacy and Data Protection:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    We respect your privacy and handle your personal information
                    in accordance with our Privacy Policy.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    By using the NimTalk - Chatrooms & Fun, you acknowledge and
                    agree to the collection, storage, and processing of your
                    personal information as described in our Privacy Policy.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">5.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Intellectual Property:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    The NimTalk - Chatrooms & Fun and its contents, including
                    but not limited to text, graphics, logos, icons, and
                    software, are the property of NimTalk LLC are protected by
                    intellectual property laws.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    You may not modify, reproduce, distribute, create derivative
                    works, or sell any part of the NimTalk - Chatrooms & Fun or
                    its contents without our prior written consent.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">6.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Disclaimer of Warranties:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    The NimTalk - Chatrooms & Fun is provided on an "as is" and
                    "as available" basis without any warranties, express or
                    implied.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    We do not guarantee the accuracy, reliability, or
                    completeness of any information or content provided through
                    the NimTalk - Chatrooms & Fun.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">7.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Limitation of Liability:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    We shall not be liable for any direct, indirect, incidental,
                    special, or consequential damages arising out of or in
                    connection with your use of the NimTalk - Chatrooms & Fun.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    We shall not be liable for any damages or loss resulting
                    from the unauthorized access to or alteration of your
                    content or data.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <div className="flex">
                <span className="font-extrabold mr-2">8.</span>
                <span className="text-orange-600 font-bold uppercase tracking-widest">
                  Modification and Termination:
                </span>
              </div>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    We reserve the right to modify or terminate the App or these
                    Terms and Conditions at any time, with or without notice.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    Upon termination, your accounts for NimTalk - Chatrooms &
                    Fun will be discontinued, and you must cease all use of the
                    App.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <div className="flex">
                <span className="font-extrabold mr-2">9.</span>
                <span className="text-orange-600 font-bold uppercase tracking-widest">
                  Governing Law and Dispute Resolution:
                </span>
              </div>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    These Terms and Conditions shall be governed by and
                    construed in accordance with the laws of [Jurisdiction].
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    Any disputes arising out of or relating to these Terms and
                    Conditions shall be resolved through arbitration or small
                    claims court, as per our discretion.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
