import Layout from "@layout";

const RefundPolicy = () => {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-16 sm:py-24 space-y-10 px-10">
        <p className="text-3xl md:text-4xl lg:text-5xl text-center uppercase tracking-widest">
          Refund Policy
        </p>
        <p className="bg-main w-fit font-bold text-white text-sm sm:text-base md:text-2xl px-1 rounded mr-2">
          NimTalk - Chatrooms & Fun's
        </p>
        <div className="space-y-4 my-4 font-light text-sm sm:text-base md:text-lg">
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">1.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Subscription Refunds:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <p>
                    We appreciate your interest in our chat application and
                    subscription services. Before subscribing, please read our
                    refund policy to understand the terms and conditions
                    regarding refunds.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">2.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Subscription Plans:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    Monthly Subscription: Our chat application offers a monthly
                    subscription plan that provides access to premium features.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    Quarterly Subscription: We also offer quarterly subscription
                    plan that offers a discounted rate compared to the monthly
                    plan.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol className="ml-2 sm:ml-6">
            <li>
              <span className="font-extrabold mr-2">3.</span>
              <span className="text-orange-600 font-bold uppercase tracking-widest">
                Refund Eligibility:
              </span>
              <ol className="ml-3 sm:ml-7">
                <li className="flex">
                  <span className="font-semibold mr-2">a.</span>
                  <p>
                    NimTalk is only refunding for premium accounts for those
                    which will be having any font issue or any technical issue.
                    You can request us through our support channel for refund by
                    sending us an email within 48 hours.
                  </p>
                </li>
                <li className="flex">
                  <span className="font-semibold mr-2">b.</span>
                  <p>
                    NimTalk will not refund for nCoin/points purchase as you
                    know it is an app virtual in-app purchase used to boost
                    chatrooms and other premium features.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="bg-main font-bold text-white text-sm sm:text-base md:text-2xl px-1 rounded mr-2">
            Contact us
          </p>
          <br />
          <p className="text-center md:text-xl">
            If you have any questions, concerns, or requests, please contact us
            at{" "}
            <a
              href="mailto:support@nimtalk.com"
              className="text-main hover:text-orange-600 hover:underline hover:underline-offset-2"
            >
              support@nimtalk.com
            </a>
          </p>
          <br />
          <span className="md:text-xl font-light mt-3 text-justify">
            Please note that this policy is subject to change without prior
            notice. It's recommended to review the refund policy before
            subscribing to our chat application and subscription services.
          </span>
        </div>
      </div>
    </Layout>
  );
};

export default RefundPolicy;
